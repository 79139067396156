import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

// 用户登录
import Login from "./login/login";
// 用户注册
import Register from "./login/register";
// 用户找回
import Forget from "./login/forget";
//首页
import MiniShop from "./minishop";

class Pages extends React.Component {
  state = {
    login_state: false,
  };
  componentDidMount() {}
  componentWillUnmount() {
    document.title = "七朵云统一认证系统";
    this.setState = () => false;
  }
  render() {
    return (
      <div className="full">
        <Router>
          <Switch>
            {/* 用户登录注册 */}
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/forget" component={Forget} />
            <Redirect to={`/login`} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default Pages;
