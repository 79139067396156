import logo from "./logo.svg";
// import "./App.css";
import { Layout, ConfigProvider } from "antd";
// import "antd/dist/antd.less";
// import 'antd/antd.min.css';

import React from "react";
import "./App.less";
import Pages from "./pages/index";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/lib/locale/zh_CN";

function App() {
  return (
    <div className="App">
      <Pages />
    </div>
  );
}

export default App;
