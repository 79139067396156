import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Spin, Layout, Card, Menu, Button, Dropdown } from "antd";
import {
  DownOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import api from "../api";
import cookie from "react-cookies";
import "./index.less";

import ContHeader from "../components/ContHeader";
import ContBreadcrumb from "../components/ContBreadcrumb";
//公共函数
import utils from "../assets/js/public";
import basicRouters from "../assets/json/basicRouters.json";

//页面引入
import Download from "./download/index.js"; //下载
// import VersionList from "./version/list"; //版本列表
// import VersionEdit from "./version/list"; //发布版本

// import PageList from "./page/list"; //页面列表
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Minishop extends React.Component {
  state = {
    loading: false,
    user_data: "",
    mode: "inline",
    theme: "light",
    routerList: [],
    routerListChild: [],
    ruleList: [],
    shopInfo: {},
    userInfo: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  async componentDidUpdate() {
    // await this.isLogin();
  }

  componentDidMount = async () => {
    // await this.isLogin();
    // await this.getUserInfo();
  };
  async isLogin() {
    // if (!cookie.load("accessToken")) {
    //   cookie.remove("accessToken", { path: "/" });
    //   localStorage.removeItem("userInfo");
    //   this.props.history.push("/login");
    //   return true;
    // }
    return false;
  }
  getUserInfo = async () => {
    let res = await api.userinfo();
    if (res.status == 200) {
      // console.log(res.data)
      this.setState({
        userInfo: res.data.Data,
      });
      localStorage.setItem("userInfo", JSON.stringify(res.data.Data));
    }
  };
  render() {
    const { loading, shopInfo, userInfo } = this.state;
    return (
      <Switch>
        {/* 下载 */}
        <Route path="/index/download" exact component={Download} />
        <Redirect to={`/index/download`} />
        {/* <Footer style={{ textAlign: "center", background: "transparent" }}>
          Copyright © 2022 宿州七朵信息技术有限公司 沪ICP备2020033741号-1
        </Footer> */}
      </Switch>
    );
  }
}

export default Minishop;
