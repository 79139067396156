import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
export default {
  //用户检测
  async check(params) {
    return await instance
      .post(`${base}/api/login/appcheck`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //用户登录
  async login(params) {
    return await instance
      .post(`${base}/api/login/applogin`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //用户获取短信
  async code(params) {
    // console.log(params)
    return await instance
      .post(`${base}/api/login/getcode`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //用户注册
  async register(params) {
    return await instance
      .post(`${base}/api/login/register`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //重置密码
  async resetPwd(params) {
    return await instance
      .post(`${base}/api/login/forget`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
};
