import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Col, Row, Spin, Modal } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import "./login.less";
import api from "../../api";
import cookie from "react-cookies";
import uitls from "../../assets/js/public";
import { message } from "antd";
import qs from "qs";

import Footer from "./footer";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    document.title = "七朵云统一认证系统 - 用户登录";
  };

  login = async (values) => {
    this.setState({ loading: true });
    values = Object.assign(values);

    console.log(location.search.split("?")[1]);
    const params = qs.parse(location.search.split("?")[1]);

    values.appid = params.appid;
    values.redirect_url = params.redirect_url;

    const {
      data: { code, data, msg },
    } = await api.login(values);

    console.log(code, data, msg);

    if (code === 200) {
      message.success("登录成功");

      const rt = {
        openid: data.user.openid,
        token: data.token.token,
        exp: data.token.exp,
      };

      console.log(`${params.redirect_url}?${qs.stringify(rt)}`);

      window.location.href = `${params.redirect_url}?${qs.stringify(rt)}`;
      return;
    }
    message.error(msg);
    this.setState({ loading: false });

    // // test
    //   message.success("登录成功");
    //   this.props.history.push("/overview");
    //   return;
  };

  render() {
    return (
      <div className="login-cont">
        <div></div>
        <div className="login-sign">
          <div className="title">
            <h1>登录</h1>
          </div>
          <Form
            className="login-form"
            onFinish={this.login}
            name="normal_login"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                size="large"
                allowClear
                prefix={
                  <UserOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                placeholder="请输入账号"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                立即登录
              </Button>

              <div className="orther">
                <div>
                  没有账号？
                  <Link to={`/register${location.search}`}>注册</Link>
                </div>
                <div>
                  忘记密码？
                  <Link to={`/forget${location.search}`}>找回</Link>
                </div>
              </div>
            </Form.Item>
            <div className="form-agreement">
              了解<a>《帐号使用协议》</a>和<a>《隐私服务政策》</a>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}
