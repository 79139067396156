import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Col, Row, Spin, Modal } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="copyright">
        <p>
          Copyright © {new Date().getFullYear()} 上海七朵信息技术有限公司
          <a
            className="mx-2"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
          >
            沪ICP备2020033741号-1
          </a>{" "}
          <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">
            沪公安网安备31011302002294号
          </a>
        </p>
      </div>
    );
  }
}
